<template>
    <NavBar :sessionKey="sessionKey" />

    <div class="chapter">
        <section id="hero">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <h1 class="text-size-80 fw-100 canela orange-text fst-italic">Capitolo {{ chapterId }} </h1>
                        <p class="text-size-32 text-white canela">{{ chapter.title }}</p>
                        <p class="light-grey-text text-size-15 fw-light helvetica" v-html="chapter.description"></p>
                    </div>
                    <div class="col-md-6 offset-md-1 justify-content-center align-items-center d-flex flex-column">
                        <video v-if="sessionKey" class="mt-5 mt-md-0"  style="width: 100%" controls>
                            <source :src="require(`@/assets/video/MC${chapterId}.mp4`)" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                        <p class="text-center text-white" v-if="!sessionKey">È necessario registrarsi per vedere il video</p>
                        <router-link to="/register"><button v-if="!sessionKey" class="orange-cta mx-auto">Iscriviti</button></router-link>
                    </div>
                </div>
            </div>
        </section>
        <section id="learn">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="decorator canela fst-italic">Imparerai...</p>
                        <ul class="light-grey-text text-size-15 fw-light helvetica" v-html="chapter.learnings"></ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <FooterLinks />
</template>

<script>
import axios from "axios"

import NavBar from "@/components/NavBarBlack.vue"
import FooterLinks from "../components/FooterLinks.vue"

export default {
    name: "Chapter",

    components: { NavBar, FooterLinks },

    data() {
        return {
            chapterId: null,
            chapter: null,
            sessionKey: null,
        }
    },

    created() {
        this.getCookie()
        this.chapterId = this.$route.params.id
        this.sessionKey = this.getCookie()
        this.getChapter()
        // if (!this.sessionKey) {
        //     this.$router.push("register")
        // }

        // if (this.sessionKey) {
        //     this.getChapter()
        // }
    },

    methods: {

        getChapter() {
			var url = process.env.VUE_APP_API_DOMAIN + "api/v1/" + this.chapterId + "/"
			axios
				.get(url)
				.then((response) => (
                    console.log(response.data),
					this.chapter = response.data
				));
		},

        getCookie() {
			var nameEQ = "sessionCookie" + "=";
			var ca = document.cookie.split(';');
			for(var i=0;i < ca.length;i++) {
				var c = ca[i];
				while (c.charAt(0)==' ') c = c.substring(1,c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
			}
			return null;
		},
    }
}
</script>

<style lang="sass" scoped>
.chapter
    background-color: #333
    #hero
        padding-top: 120px
        padding-bottom: 70px

    #learn
        padding-bottom: 100px
        .decorator
            font-size: 10vw
            color: rgba(255,255,255,0.1)


</style>