<template>
    <nav>
        <div class="container-fluid px-5 d-none d-md-block">
            <div class="row position-relative">
                <router-link to="/">
                    <img src="@/assets/img/versus-logo-white.svg" class="position-absolute">
                </router-link>
                <div class="col-6 d-flex align-items-center">
                    <a href="https://versusprojects.com/it/vetrine-refrigerate/" class="nav-link">PRODOTTI</a>
                    <a href="https://versusprojects.com/it/sostenibilita/" class="nav-link">SOSTENIBILITÀ</a>
                    <a href="https://versusprojects.com/it/chi-siamo/" class="nav-link">CHI SIAMO</a>
                    <a href="https://versusprojects.com/it/custom-made/" class="nav-link">PROGETTI SU MISURA</a>
                </div>
                <div class="col-6 d-flex flex-row justify-content-end" v-if="released">
                    <!-- <button v-if="sessionKey" class="btn btn-white"><router-link to="/account">Il mio account</router-link></button> -->
                    <button v-if="!sessionKey" class="btn btn-white"><router-link to="/login">ACCEDI</router-link></button>
                    <button v-if="!sessionKey" class="btn btn-black"><router-link to="/register">Iscriviti</router-link></button>
                </div>
            </div>
        </div>
        <div class="container-fluid px-5 d-block d-md-none">
            <div class="row position-relative text-center">
                <router-link to="/">
                    <img src="@/assets/img/versus-logo-white.svg" class="position-absolute">
                </router-link>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "Home",
        props: ['sessionKey', 'released']
    }
</script>

<style lang="sass" scoped>
nav
    position: absolute
    width: 100%
    display: block
    padding-top: 20px
    padding-block-end: 20px
    .nav-link
        margin-right: 40px
        font-family: "Helvetica"
        font-size: 12px
        font-weight: 300
        text-transform: uppercase
        letter-spacing: 2px
        color: #fff
    img
        width: 150px
        left: calc(50% - 75px)
        top: 2px

    .btn
        border-radius: 0
        padding: 16px 28px 14px
        border: 1px solid
        font-family: "Helvetica"
        font-size: 12px
        font-weight: 500
        letter-spacing: 2px
        margin-left: 20px
        text-transform: uppercase
        &.btn-black
            background-color: #fff
            color: #fff
            border-color: #fff
        &.white
            border-color: #fff
            background-color: #fff
            color: #fff

</style>
