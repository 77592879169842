<template>
<FormMessageModal v-if="viewMessageModal" @closeModal="toggleMessageModal()" :message="modalMessage" :id="modalId" />

<section class="about">
	<div class="container-fluid h-vh-100">
		<div class="row h-vh-100">
			<div class="col-lg-6 col-md-6 ps-0 py-0 img-col">
			</div>
			<div class="col-lg-6 col-md-6 content-col d-flex flex-column justify-content-center">
				<h1 class="canela fw-100 orange-text text-size-80">Iscriviti</h1>
				<p class="fw-light me-md-5 mb-5 mt-0">Compila i campi per creare il tuo profilo e accedere ai contenuti</p>
				<form class="form" v-on:submit.prevent=register()>
					<input required class="field" type="text" v-model="first_name" placeholder="First name">
					<input required class="field" type="text" v-model="last_name" placeholder="Last name">
					<input required class="field" type="email" v-model="email" placeholder="Email">
					<input required class="field" type="password" v-model="password" placeholder="Password">
					<select required class="field-select" name="select" v-model="profession">
						<option value="" disabled>Profession</option>
						<optgroup label="Professional horeca">
							<option value="chef">Chef</option>
							<option value="pasticcere">Pasticcere</option>
							<option value="gelatiere">Gelatiere</option>
						</optgroup>
						<optgroup label="Professional horeca">
							<option value="ristorante">Ristorante</option>
							<option value="pasticceria/panetteria">Pasticceria/Panetteria</option>
							<option value="gelateria">Gelateria</option>
							<option value="enoteca/vineria">Enoteca/Vineria</option>
							<option value="macelleria">Macelleria</option>
							<option value="other">Other</option>
						</optgroup>
						<option value="privato">Privato</option>
						<option value="studente">Studente</option>
						<option value="altro">Altro</option>
					</select>
					<div class="col-12 py-0">
						<div class="form-check mt-0">
							<input required type="checkbox" class="form-check-input" />
							<label class="form-check-label" for="group_2">Accetto di ricevere comunicazioni di carattere commerciale*</label>
						</div>
						<div class="form-check mt-0">
							<input required type="checkbox" class="form-check-input" />
							<label class="form-check-label" for="group_1">Accetto la  <a href="/privacy-policy.html"><u>Privacy Policy</u></a>*</label>
						</div>
					</div>
					<input class="orange-cta ms-0 me-auto border-0 mt-5" type="submit" value="Iscriviti">
					<p class="mt-4">Hai già un account? <router-link class="orange-text" to="/login">Accedi</router-link></p>
					<p v-if="errorList.length > 0">
						<span v-for="error in errorList" v-bind:key="error">{{ error }}</span>
					</p>
				</form>
			</div>
		</div>
	</div>
</section>
</template>

<script>
import axios from "axios"
import FormMessageModal from "@/components/FormMessageModal.vue"

export default {
	name: "Register",
	data() {
		return {
			first_name: '',
			last_name: '',
			email: '',
			password: '',
			profession: '',
			errorList: [],

			viewMessageModal: false,
            modalMessage: '',
			modalId: '',
		}
	},

	components: { FormMessageModal },

	methods: {
		register() {
			var self = this
			this.errorList = []
			var url = process.env.VUE_APP_API_DOMAIN + "api/v1/rest-auth/registration/"
			var data = {
				'first_name': this.first_name,
				'last_name': this.last_name,
				'email': this.email,
				'profession': this.profession,
				'password1': this.password,
				'password2': this.password,
			}
			axios
				.post(url, data)
				.then((response) => (
					this.modalMessage = "Grazie, ti abbiamo inviato un'email di conferma per verificare il tuo account. Per favore controlla la tua casella di posta",
					this.toggleMessageModal(),
					this.modalId = "idstart",
					this.setCookie("sessionCookie",response.data.key,90)
				))
				.catch(function(error) {
					for (var key in error.response.data) {
						self.errorList.push(error.response.data[key][0])
					}
				})
		},
		setCookie(name,value,days) {
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + (value || "")  + expires + "; path=/";
		},

		toggleMessageModal() {
			this.viewMessageModal = !this.viewMessageModal
		}
	}

}
</script>

<style lang="sass" scoped>
	.about
		height: 100%
		min-height: 100vh
		@media (min-width: 768px)
			.h-vh-100
				min-height: 100vh
		@media (max-width: 768px)
			height: auto
			padding-bottom: 70px
		.img-col
			background-image: url("~@/assets/img/register.jpg")
			background-size: cover
			background-position: center center
			@media (max-width: 768px)
				min-height: 220px
		.content-col
			padding-top: 50px
			padding-bottom: 50px
			padding-left: 100px
			padding-right: 100px
			@media (max-width: 768px)
				padding-right: 22px
				padding-left: 22px
				padding-top: 50px
			form
				input.field, select
					width: 100%
					display: block
					background-color: #f6f6f6
					padding: 18px 15px
					margin-bottom: 20px
					border: none
					border-radius: 0

					
</style>
